

































































#stocks{
  .table-custom{
    height: auto;
    width:auto;
    margin-top:25px;
    margin-bottom:50px;
  }
  .header-table{
    margin-right:50px !important;
  }
}
#valores{
  .table-custom{
    height: auto;
    width:100%;
    margin-top:25px;
    margin-bottom:50px;
  }
  table{
    border-spacing: 10px !important;
  }
  .header-table{
    margin-right:50px !important;
  }
}
.header-table{
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
}

.top-table{
  width: 100%;
}

.table-custom{
  height: 280px;
  width: 417px;
  background-color: #F3F4F7;
  overflow-y: scroll;
  border: 1px solid lightgrey;
  border-radius: 5px;
}

.table-row{
  font-size: 12px;
  background-color: white;
  font-weight: normal;
}

#best-bullish-values{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 208.5px;
    height: 40px;
  }
  .table-custom{
    height: 220px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }
}

#hot-values{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 208.5px;
    height: 40px;
    background-color: var(--color-active-surface);
    color: var(--color-text-secondary);
  }
  .table-custom{
    height: 220px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
    background-color: var(--color-text-secondary);
    border: 1px solid var(--color-text-secondary);
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
    background-color: var(--color-surface);
    color: var(--color-text-secondary);
  }
  .top-table{
    width: 100%;
  }
}

#best-sectors{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 208.5px;
    height: 40px;
  }
  .table-custom{
    height: 220px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }

  .header-table{
    background-color: var(--color-active-surface);
    color: var(--color-text-secondary);
  }
  .table-custom{
    background-color: var(--color-text-secondary);
    border: 1px solid var(--color-text-secondary);
  }

  .table-row{
    background-color: var(--color-surface);
    color: var(--color-text-secondary);
  }
}

#portfolio-changes{
  table {
    width: 100%;
  }

  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: auto;
    height: 40px;
    color: var(--color-text-secondary);
    background-color: var(--color-active-surface);
  }
  .table-custom{
    height: 220px;
    width: auto;
    background-color: var(--color-surface);
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: var(--color-surface);
    color: var(--color-text-secondary);
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }
}

#twin-souls{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 417px;
    height: 40px;
    background-color: var(--color-active-surface);
    color: var(--color-text-secondary);
  }
  .table-custom{
    height: 180px;
    width: auto;
    background-color: var(--color-text-secondary);
    border: 1px solid var(--color-text-secondary);
    overflow-y: scroll;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: var(--color-surface);
    color: var(--color-text-secondary);
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }
}

#artificial-inteligence{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 139px;
    height: 40px;
  }
  .table-custom{
    height: 220px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }

  .header-table{
    background-color: var(--color-active-surface);
    color: var(--color-text-secondary);
  }
  .table-custom{
    background-color: var(--color-text-secondary);
    border: 1px solid var(--color-text-secondary);
  }

  .table-row{
    background-color: var(--color-surface);
    color: var(--color-text-secondary);
  }
}

#waiting-values{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 104px;
    height: 40px;
  }
  .table-custom{
    height: 220px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }

  .header-table{
    background-color: var(--color-active-surface);
    color: var(--color-text-secondary);
  }
  .table-custom{
    background-color: var(--color-text-secondary);
    border: 1px solid var(--color-text-secondary);
  }

  .table-row{
    background-color: var(--color-surface);
    color: var(--color-text-secondary);
  }
}
#open-positions{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 104px;
    height: 40px;
  }
  .table-custom{
    height: auto;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }

  .header-table{
    background-color: var(--color-active-surface);
    color: var(--color-text-secondary);
  }
  .table-custom{
    background-color: var(--color-text-secondary);
    border: 1px solid var(--color-text-secondary);

  }

  .table-row{
    background-color: var(--color-surface);
    color: var(--color-text-secondary);
  }
}
#last-ipos{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 104px;
    height: 40px;
  }
  .table-custom{
    height: auto;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }

  .header-table{
    background-color: var(--color-active-surface);
    color: var(--color-text-secondary);
  }
  .table-custom{
    background-color: var(--color-text-secondary);
    border: 1px solid var(--color-text-secondary);
  }

  .table-row{
    background-color: var(--color-surface);
    color: var(--color-text-secondary);
  }
}

#best-bullish{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 208.5px;
    height: 40px;
  }
  .table-custom{
    height: 220px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }

  .header-table{
    background-color: var(--color-active-surface);
    color: var(--color-text-secondary);
  }
  .table-custom{
    background-color: var(--color-text-secondary);
    border: 1px solid var(--color-text-secondary);
  }

  .table-row{
    background-color: var(--color-surface);
    color: var(--color-text-secondary);
  }
}

#fundamental{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 357px;
    height: 40px;
  }
  .table-custom{
    width: 362px !important;
    height: 180px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }
}

#fundamental-mobile{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 357px;
    height: 40px;
  }
  .table-custom{
    width: 332px !important;
    height: 180px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }
}
