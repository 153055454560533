























































































































































































































































































































































  html{
    overflow-y: scroll;
  }
  
  h3 {
    margin-top: 0;
    padding-top: 50px;
  }

  h3, p {
    color: var(--color-text-primary);
  }

  #main-container {
    background-color: var(--color-background);
  }

  .tables-container {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
  }
  .not-results-main-container{
    margin-left: 40px;
  }
  .ia-buttons-box .mdc-button {
    width: max-content;
    margin: 20px 0 ;
    display: block;
  }

  @media screen and (max-width: 599px){
    .table-box {
      width: 100%;
    }
    .btn-redirect{
      width: 325px !important;
    }
  }

  @media (min-width: 600px) {
    .mdc-layout-grid__cell--span-4 {
      grid-column-end: span 6;
    }
  }

  @media (min-width: 890px) {
    .results-main-container {
      margin-left: 40px;
    }
  }

  @media (max-width: 889px) {
    .results-main-container {
      margin-left: 25px;
    }
  }
  

  .highcharts-figure,
.highcharts-data-table table {
    min-width: 320px;
    max-width: 500px;
    margin: 1em auto;
}


.highcharts-data-table table {
    font-family: Verdana, sans-serif;
    border-collapse: collapse;
    border: 1px solid #ebebeb;
    margin: 5px auto;
    text-align: center;
    width: 100%;
    max-width: 90px;
}

.highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
}

.highcharts-data-table th {
    font-weight: 600;
    padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
    padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
}

.highcharts-data-table tr:hover {
    background: #f1f7ff;
}
.highcharts-credits{
  display:none !important;
  color:white;
}
th { 
  height:90px !important;
}

a {
  text-decoration: none;
}
.do-test-button{
  border: none;
  background-color: #0faed7;
  color: white;
  height: 40px;
  width: 200px;
  border-radius: 25px;
  font-size: 15px;
  text-transform: uppercase;
  cursor: pointer;
}

.secondary-ia-container{
  text-align: center;
  position: absolute;
  top: 40%;
}

.text-ia{
  width: 50%;
  margin: auto;
}

.not-results-mobile{
  position: absolute;
  top: 30%;
  text-align: center;
}

.table-custom {
  margin-bottom: 25px;
}

.container-image{
    /* Aplica un margen automático para centrar el div horizontalmente */
    margin: 0 auto;
    /* Ajusta el ancho máximo del contenedor */
    max-width: 800px;
    max-height: auto;
    
  }



  .container-image img{
    max-width: 500px;   
    max-height: 800px;
    
    border-radius: 5px;
  }

  @media screen and (min-width: 768px) {
    .container-image img {
      width: 500px;
      margin: 20px;
    }
  }

  .text-center {
    text-align: center;
    margin: 20px;
  }

  .container-image p {    
    text-align: justify;
  }

  .unauthorized {
    display: flex;
    align-items: center;
    height: 100%;
    text-align: center;
    justify-content: center;
    font-size: 20px;
  }

  .login-button{
    color: white;
    background-color: #00add8;
    border-radius: 20px;
    cursor: pointer;
    padding: 10px;
    text-decoration: none;
    border: none;
    outline: none;
    width: 150px;
    margin-top: 20px;
    font-size: 14px;
  }
  .login-button:hover{
    background-color: #00bdec;
  }

  .not-allowed-artificial-intelligence-container{
    text-align: center;
    margin-top: 50px;
  }
  
  .link-to-store{
    color: #35add8 !important;
  }

  .not-allowed-artificial-intelligence-title{
    color: #FF9B00;
    font-weight: bold;
  }

